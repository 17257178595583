import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'

import styles from './style.module.scss'
import sgMedia from '@assets/images/placeholder/SGMEDIA.svg'
// import { APP_STORE_URL, PLAY_STORE_URL } from '@shared-submodule/sm-constants'
import logo from '@assets/images/logoDark.svg'
import facebookIcon from '@assets/images/icon/facebook-icon.png'
import instagramIcon from '@assets/images/icon/instagram-icon.png'
import twitterIcon from '@assets/images/icon/twitter-icon.png'
import youtubeIcon from '@assets/images/icon/youtube-icon.png'
// import appStore from '@assets/images/icon/apple-store.svg'
// import googlePlay from '@assets/images/icon/play-store.svg'
import { allRoutes } from '@shared-submodule/sm-constants/allRoutes'
import { Col, Row } from 'react-bootstrap'
import { FACEBOOK_URL, INSTAGRAM_URL, TWITTER_URL, YOUTUBE_URL } from '@shared-submodule/sm-constants'
// import { FacebookIcon, InstagramIcon, TwitterIcon, YoutubeIcon } from '../ctIcons'
// import useWindowSize from '@shared/hooks/windowSize'
// import useOnMouseAndScroll from '@shared/hooks/useOnMouseAndScroll'
import googlePlay from '@assets/images/icon/google-play.svg'
import appleStore from '@assets/images/icon/app-store.svg'
// import clockIcon from '@assets/images/icon/clock.svg'
const MyImage = dynamic(() => import('@shared/components/myImage'))
// Don't remove we might require this in future
const CustomLink = dynamic(() => import('../customLink'))
// const PromotionSmall = dynamic(() => import('@shared/components/adsPromotion/promotionSmall'))
// const FacebookIcon = dynamic(() => import('@shared/components/ctIcons').then((e) => e.FacebookIcon))

function Footer() {
  const { t } = useTranslation()
  // const [width] = useWindowSize()
  // const { isLoaded } = useOnMouseAndScroll()
  // const [visible, setVisible] = useState(true)
  return (
    <footer className={`${styles.siteFooter} text-center text-sm-start content-visibility-auto`} id="footer">
      <div className="container">
        <div className={`${styles.footerBottom}`}>
          <Row>
            {/* <Col xl={7} lg={6}> */}
            <Col>
              <Row>
                <Col xl={6} lg={7} sm={6}>
                  <div className={`${styles.logoBlock}`}>
                    <div className='d-flex align-items-center justify-content-center justify-content-sm-start mb-2'>
                      <div className={`${styles.footerLogo} d-inline-block`}>
                        <MyImage src={logo} alt="logo" layout="responsive" />
                      </div>
                      <div className={styles.sgLogo}>
                        <MyImage src={sgMedia} alt="SG media" width={63} />
                      </div>
                    </div>
                    <p>{t('common:FooterInfo')}</p>
                  </div>
                </Col>
                <Col xl={5} lg={7} sm={5} className='offset-xl-1'>
                  <ul className={`${styles.footerMenu} mt-3 mt-sm-0 d-flex flex-wrap`}>
                    <li>
                      <CustomLink href={allRoutes.aboutUs} prefetch={false}>{t('common:AboutUs')}</CustomLink>
                    </li>
                    <li>
                      <CustomLink href={allRoutes.privacyPolicy} prefetch={false}>{t('common:PrivacyPolicy')}</CustomLink>
                    </li>
                    <li>
                      <CustomLink href={allRoutes.contactUs} prefetch={false}>{t('common:ContactUs')}</CustomLink>
                    </li>
                    <li>
                      <CustomLink href={allRoutes.termsAndConditions} prefetch={false}>
                        <a>{t('common:TermsandConditions')}</a>
                      </CustomLink>
                    </li>
                    <li>
                      <CustomLink href={allRoutes.disclaimer} prefetch={false}>{t('common:Disclaimer')}</CustomLink>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={6}>
              <Row>
                {/* <Col lg={5} sm={6}>
                  <p className="mt-4 mt-lg-0 mb-2 mb-sm-3">{t('common:DownloadTheApp')}</p>
                  <div className={`${styles.downloadApp} d-flex justify-content-center justify-content-sm-start `}>
                    <CustomLink href={APP_STORE_URL} passHref>
                      <a target="_blank">
                        <MyImage src={appStore} alt="appStore" />
                      </a>
                    </CustomLink>
                    <CustomLink href={PLAY_STORE_URL} passHref>
                      <a target="_blank">
                        <MyImage src={googlePlay} alt="googlePlay" />
                      </a>
                    </CustomLink>
                  </div>
                </Col> */}
                <Col lg={6} sm={6}>
                  <p className="mt-4 mt-lg-0 mb-2 mb-sm-3">{t('common:ConnectWithUs')}</p>
                  <ul className={`${styles.socialMenu} d-flex text-uppercase justify-content-center justify-content-sm-start`}>
                    <li>
                      <a href={FACEBOOK_URL} target="_blank" rel="noreferrer">
                        {/* <facebookIcon/> */}
                        <MyImage src={facebookIcon} alt="facebook" layout="responsive" />
                      </a>
                    </li>
                    <li>
                      <a href={TWITTER_URL} target="_blank" rel="noreferrer">
                        <MyImage src={twitterIcon} alt="twitter" layout="responsive" />
                      </a>
                    </li>
                    <li>
                      <a href={INSTAGRAM_URL} target="_blank" rel="noreferrer">
                        <MyImage src={instagramIcon} alt="instagram" layout="responsive" />
                      </a>
                    </li>
                    <li>
                      <a href={YOUTUBE_URL} target="_blank" rel="noreferrer">
                        <MyImage src={youtubeIcon} alt="youtube" layout="responsive" />
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col lg={6} sm={6}>
                  <div className=" flex flex-col">
                    {/* <h4 className="text-lg">Download the App</h4> */}
                    <p className="mt-4 mt-lg-0 mb-2 mb-sm-3">Download App</p>
                    <div className={`row ${styles.mtNe5}`}>
                      <div className='col-6 p-0 cursor-pointer'>
                        <CustomLink href="https://play.google.com/store/apps/details?id=com.oneturf.news.live">
                          <MyImage
                            src={googlePlay}
                            alt="Apple Store"
                          />
                        </CustomLink>
                      </div>
                      <div className='col-6 p-0 cursor-pointer'>
                        <CustomLink href="https://apps.apple.com/in/app/oneturf-news-live-score/id6504330330">
                          <MyImage
                            src={appleStore}
                            alt="Apple Store"
                          />
                        </CustomLink>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      {/* {
        isLoaded && width > 767 && (
          <PromotionSmall/>
        )
      } */}
    </footer>
  )
}
export default Footer
